import { Flex, Heading, Image, Text } from '@chakra-ui/react'

import { useTranslation } from '~/lib/i18n'
import Comets from './Comets'

const Chapter = ({ translationKey, chapter }: { translationKey: string; chapter: number }) => {
  const { t } = useTranslation('home')

  return (
    <>
      <Flex pos="relative" flexDir="column" alignItems="center" py={{ base: '64px', lg: 40 }} px={{ base: 4, lg: 0 }}>
        {translationKey === 'aNewEpoch' && <Comets />}
        {translationKey === 'theDawnOfPacto' && (
          <Image
            zIndex={0}
            pos="absolute"
            left={0}
            top={{ base: '172px', sm: '60px', lg: '100px', xl: '40px', '2xl': '-10%' }}
            right={0}
            margin="0 auto"
            src="dawn.png"
          />
        )}

        <Text
          textAlign="center"
          textTransform="uppercase"
          color="text.secondary"
          fontSize="12px"
          lineHeight="20px"
          letterSpacing="0.16em"
        >
          {`${t('chapter')} 0${chapter}`}
        </Text>
        <Heading
          fontSize={{ base: '46px', lg: '56px' }}
          lineHeight={{ base: '52px', lg: '64px' }}
          textAlign="center"
          mt={2}
          zIndex={1}
        >
          {t(`chapters.${translationKey}.title`)}
        </Heading>
        <Text fontSize="16px" lineHeight="24px" color="text.secondary" textAlign="center" mt={3} zIndex={1}>
          {t(`chapters.${translationKey}.subtitle`)}
        </Text>
        {translationKey === 'theMerge' && <Image w="360px" h="240px" src="the-merge.png" mt="100px" />}
        <Text fontSize="21px" lineHeight="32px" color="text.secondary" maxW="720px" mt="120px" mb={8} zIndex={1}>
          {t(`chapters.${translationKey}.content`)}
        </Text>
        <Flex
          display={{ base: 'none', lg: 'flex' }}
          flexDir="column"
          w={{ base: 'full', lg: '720px' }}
          gap={4}
          zIndex={1}
        >
          <Flex gap={3} justifyContent="flex-start" alignItems="center">
            <Image src="arrow-right.svg" w="24px" h="24px" />
            <Text fontWeight="500" fontSize="21px" lineHeight="32px">
              {t(`chapters.${translationKey}.bullets.one`)}
            </Text>
          </Flex>
          <Flex gap={3} justifyContent="flex-start" alignItems="center">
            <Image src="arrow-right.svg" w="24px" h="24px" />
            <Text fontWeight="500" fontSize="21px" lineHeight="32px">
              {t(`chapters.${translationKey}.bullets.two`)}
            </Text>
          </Flex>
          <Flex gap={3} justifyContent="flex-start" alignItems="center">
            <Image src="arrow-right.svg" w="24px" h="24px" />
            <Text fontWeight="500" fontSize="21px" lineHeight="32px">
              {t(`chapters.${translationKey}.bullets.three`)}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {/* <Flex
        w="full"
        borderColor="border.default"
        borderTopWidth="1px"
        borderBottomWidth={{ base: 0, lg: '1px' }}
        justifyContent="space-between"
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <FeaturedItem
          title={t(`chapters.${translationKey}.features.one.title`)}
          subtitle={t(`chapters.${translationKey}.features.one.subtitle`)}
          isLast={false}
          isFirst
          imageSrc="/features/enhanced-yield.png"
        />
        <FeaturedItem
          title={t(`chapters.${translationKey}.features.two.title`)}
          subtitle={t(`chapters.${translationKey}.features.two.subtitle`)}
          isLast={false}
          isFirst={false}
          imageSrc="/features/insurance-pool.png"
        />
        <FeaturedItem
          title={t(`chapters.${translationKey}.features.three.title`)}
          subtitle={t(`chapters.${translationKey}.features.three.subtitle`)}
          isLast
          isFirst={false}
          imageSrc="/features/staking-passive-income.png"
        />
      </Flex> */}
    </>
  )
}

export default Chapter
