import { Flex, Image, Stack, Text, useBreakpointValue } from '@chakra-ui/react'

interface Props {
  expanded?: boolean
  isLast: boolean
  isFirst: boolean
  title: string
  subtitle: string
  imageSrc: string
}

const FeaturedItem = ({ expanded = false, isLast, isFirst, title, subtitle, imageSrc }: Props) => {
  const imageSize = expanded ? '140px' : '64px'

  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <Flex
      flexDir={expanded ? 'column' : 'row'}
      gap={expanded ? 24 : 10}
      flex={1}
      px={{ base: 4, lg: 10 }}
      pb={10}
      pt={expanded ? { base: '72px', lg: 24 } : 10}
      borderColor="border.default"
      borderLeft="none"
      borderRightWidth={isLast ? 0 : { base: 0, lg: '1px' }}
      borderBottomWidth={{ base: '1px', lg: 'none' }}
      pos="relative"
      alignItems="center"
    >
      {!isMobile && (
        <>
          {!isFirst && (
            <>
              <Image pos="absolute" left="-1px" top="-1px" src="/edges/corner-top-left.svg" />
              <Image pos="absolute" left="-1px" bottom="-1px" src="/edges/corner-bottom-left.svg" />
            </>
          )}
          {!isLast && (
            <>
              <Image pos="absolute" right="-1px" bottom="-1px" src="/edges/corner-bottom-right.svg" />
              <Image pos="absolute" right="-1px" top="-1px" src="/edges/corner-top-right.svg" />
            </>
          )}
        </>
      )}
      <Image src={imageSrc} w={imageSize} h={imageSize} />
      <Stack spacing="11px">
        <Text
          fontSize={expanded ? '21px' : '16px'}
          lineHeight={expanded ? '32px' : '24px'}
          fontWeight={expanded ? 600 : 500}
          color="text.primary"
        >
          {title}
        </Text>
        <Text fontSize="16px" lineHeight="24px" color="text.secondary">
          {subtitle}
        </Text>
      </Stack>
    </Flex>
  )
}

export default FeaturedItem
