import { Box } from '@chakra-ui/react'

const Comets = () => (
  <>
    <Box
      pos="absolute"
      left={{ base: '55px', md: '160px' }}
      top={{ base: 0, md: '30px' }}
      w="8px"
      height="150px"
      borderRadius="100px"
      overflow="clip"
      transform="matrix(-0.71, 0.71, 0.71, 0.71, 0, 0)"
      backgroundBlendMode="color-burn, normal"
      background="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.56) 100%)"
    />
    <Box
      pos="absolute"
      left={{ base: '120px', lg: '339px' }}
      top="97px"
      w="8px"
      height="150px"
      borderRadius="100px"
      overflow="clip"
      transform="matrix(-0.71, 0.71, 0.71, 0.71, 0, 0)"
      backgroundBlendMode="color-burn, normal"
      background="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.56) 100%)"
    />

    <Box
      pos="absolute"
      right={{ base: '120px', lg: '370px' }}
      top="91px"
      w="8px"
      height="150px"
      borderRadius="100px"
      overflow="clip"
      transform="matrix(-0.71, 0.71, 0.71, 0.71, 0, 0)"
      backgroundBlendMode="color-burn, normal"
      background="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.56) 100%)"
    />
    <Box
      pos="absolute"
      right="69px"
      top={{ base: 0, md: '62px' }}
      w="8px"
      height="150px"
      borderRadius="100px"
      overflow="clip"
      transform="matrix(-0.71, 0.71, 0.71, 0.71, 0, 0)"
      backgroundBlendMode="color-burn, normal"
      background="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.56) 100%)"
    />
    <Box
      display={{ base: 'none', lg: 'block' }}
      pos="absolute"
      right="153px"
      top="303px"
      w="8px"
      height="150px"
      borderRadius="100px"
      overflow="clip"
      transform="matrix(-0.71, 0.71, 0.71, 0.71, 0, 0)"
      backgroundBlendMode="color-burn, normal"
      background="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.56) 100%)"
    />
    <Box
      pos="absolute"
      display={{ base: 'none', lg: 'block' }}
      left="105px"
      top="260px"
      w="8px"
      height="150px"
      borderRadius="100px"
      overflow="clip"
      transform="matrix(-0.71, 0.71, 0.71, 0.71, 0, 0)"
      backgroundBlendMode="color-burn, normal"
      background="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.56) 100%)"
    />
  </>
)

export default Comets
