import { Box, Flex, Heading, Image, Link as ChakraLink, Text } from '@chakra-ui/react'
import Link from 'next/link'

import Chapter from '~/components/home/Chapter'
import FeaturedItem from '~/components/home/FeaturedItem'
import { ButtonSize, ButtonVariant, CustomButton } from '~/components/layout/CustomButton'
import { DISCORD_URL, MEDIUM_LAUNCH_POST_URL } from '~/constants'
import { useTranslation } from '~/lib/i18n'

const Home = () => {
  const { t } = useTranslation('home')

  return (
    <>
      <Flex pos="relative" h={{ base: 'initial', lg: '720px' }} flexDir="column" overflow="hidden">
        <Flex flexDir="column" pt={{ base: '56px', lg: '140px' }} pl={{ base: 6, lg: 60 }} px={{ base: 6, lg: 'none' }}>
          <Heading
            fontSize={{ base: '46px', lg: '72px' }}
            lineHeight={{ base: '52px', lg: '80px' }}
            fontFamily="Roboto Slab"
            fontWeight="600"
            color="text.primary"
            mb="27px"
            maxW="776px"
            zIndex={1}
          >
            {t('hero.title')}
          </Heading>
          <Text maxW="605px" fontSize="21px" lineHeight="32px" color="text.secondary" zIndex={1}>
            {t('hero.subtitle')}
          </Text>
          <Flex gap={{ base: 4, lg: 6 }} mt={10} mb="81px" zIndex={1} flexDir={{ base: 'column', lg: 'row' }}>
            <ChakraLink as={Link} href={MEDIUM_LAUNCH_POST_URL} _hover={{ textDecoration: 'none' }}>
              <CustomButton w={{ base: 'full', lg: '180px' }} size={ButtonSize.LARGE} variant={ButtonVariant.PRIMARY}>
                {t('hero.buttons.launch')}
              </CustomButton>
            </ChakraLink>
            {/* 
              TODO: Add again once we have Docs
            <ChakraLink as={Link} href="/dashboard" _hover={{ textDecoration: 'none' }}>
              <CustomButton w={{ base: 'full', lg: '180px' }} size={ButtonSize.LARGE} variant={ButtonVariant.OUTLINE}>
                {t('hero.buttons.docs')}
              </CustomButton>
            </ChakraLink> */}
          </Flex>
          <Image
            w="600px"
            h="600px"
            src="/comet-planet.png"
            pos="absolute"
            right={{ base: -22, lg: 0 }}
            bottom={{ base: -73, lg: 0 }}
          />
        </Flex>
      </Flex>

      <Flex
        w="full"
        borderColor="border.default"
        borderTopWidth="1px"
        borderBottomWidth={0}
        justifyContent="space-between"
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <FeaturedItem
          expanded
          title={t('features.enhancedYield.title')}
          subtitle={t('features.enhancedYield.subtitle')}
          isLast={false}
          isFirst
          imageSrc="/features/enhanced-yield.png"
        />
        <FeaturedItem
          expanded
          title={t('features.insurancePool.title')}
          subtitle={t('features.insurancePool.subtitle')}
          isLast={false}
          isFirst={false}
          imageSrc="/features/insurance-pool.png"
        />
        <FeaturedItem
          expanded
          title={t('features.stakingAndPassiveIncome.title')}
          subtitle={t('features.stakingAndPassiveIncome.subtitle')}
          isLast
          isFirst={false}
          imageSrc="/features/staking-passive-income.png"
        />
      </Flex>

      <Chapter chapter={1} translationKey="theDawnOfPacto" />
      {/* TODO: Reinstante once we decide on the whole suite
      <Chapter chapter={2} translationKey="aNewEpoch" />
      <Chapter chapter={3} translationKey="theMerge" /> */}

      <Flex flexDir="column" justifyContent="space-between" alignItems="center" p={{ base: 6, lg: 10 }} h="720px">
        <Flex w="100%" justifyContent="space-between">
          <Text fontSize="xs" color="text.secondary" textTransform="uppercase">
            {t('social.corners.news')}
          </Text>
          <Text fontSize="xs" color="text.secondary" textTransform="uppercase">
            {t('social.corners.community')}
          </Text>
        </Flex>
        <Flex flexDir="column" w="full" alignItems="center" pos="relative" px={{ base: '30px', lg: 0 }}>
          <Box
            pos="absolute"
            h="1px"
            width={{ base: 'full', lg: '900px' }}
            background="linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.16) 21.43%, rgba(255, 255, 255, 0.16) 50.36%, rgba(255, 255, 255, 0.16) 78.57%, rgba(0, 0, 0, 0) 100%)"
            mb={6}
          />
          <Flex
            gap={6}
            alignItems="center"
            w="full"
            maxW={{ base: '330px', lg: '602px' }}
            h="full"
            maxH={{ base: '138px', lg: '194px' }}
            justifyContent="center"
            pos="relative"
          >
            <Box
              h="354px"
              w="1px"
              background="linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.16) 21.43%, rgba(255, 255, 255, 0.16) 50.36%, rgba(255, 255, 255, 0.16) 78.57%, rgba(0, 0, 0, 0) 100%)"
              mt={6}
            />

            <ChakraLink href={DISCORD_URL} target="_blank" rel="noreferrer">
              <CustomButton
                w={{ base: 'full', lg: '552px' }}
                maxW={{ base: '280px', lg: 'initial' }}
                py={10}
                h="full"
                maxH={{ base: '88px', lg: '144px' }}
                minH="88px"
                fontSize={{ base: '32px', lg: '56px' }}
                lineHeight={{ base: '40px', lg: '64px' }}
                fontWeight={500}
                textTransform="capitalize"
                letterSpacing="normal"
              >
                {t('social.discord')}
              </CustomButton>
            </ChakraLink>
            <Box
              h="354px"
              w="1px"
              background="linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.16) 21.43%, rgba(255, 255, 255, 0.16) 50.36%, rgba(255, 255, 255, 0.16) 78.57%, rgba(0, 0, 0, 0) 100%)"
              mt={6}
            />
            <Image pos="absolute" right="-3px" top="-3px" src="cross.svg" w="7px" />
            <Image pos="absolute" left="-3px" top="-3px" src="cross.svg" w="7px" />
            <Image pos="absolute" right="-3px" bottom="-3px" src="cross.svg" w="7px" />
            <Image pos="absolute" left="-3px" bottom="-3px" src="cross.svg" w="7px" />
          </Flex>
          <Box
            pos="absolute"
            bottom={0}
            h="1px"
            width={{ base: 'full', lg: '900px' }}
            background="linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.16) 21.43%, rgba(255, 255, 255, 0.16) 50.36%, rgba(255, 255, 255, 0.16) 78.57%, rgba(0, 0, 0, 0) 100%)"
            mt={6}
          />
        </Flex>
        <Flex w="100%" justifyContent="space-between">
          <Text fontSize="xs" color="text.secondary" textTransform="uppercase">
            {t('social.corners.events')}
          </Text>
          <Text fontSize="xs" color="text.secondary" textTransform="uppercase">
            {t('social.corners.updates')}
          </Text>
        </Flex>
      </Flex>
    </>
  )
}

export default Home
